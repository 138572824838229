import { OptionsColor, deepExtend, executeOnSingleOrMultiple } from "tsparticles-engine";
import { SplitFactor } from "./SplitFactor";
import { SplitRate } from "./SplitRate";
export class Split {
    constructor() {
        this.count = 1;
        this.factor = new SplitFactor();
        this.rate = new SplitRate();
        this.sizeOffset = true;
    }
    load(data) {
        var _a;
        if (!data) {
            return;
        }
        if (data.color !== undefined) {
            this.color = OptionsColor.create(this.color, data.color);
        }
        if (data.count !== undefined) {
            this.count = data.count;
        }
        this.factor.load(data.factor);
        this.rate.load(data.rate);
        this.particles = executeOnSingleOrMultiple(data.particles, (particles) => {
            return deepExtend({}, particles);
        });
        if (data.sizeOffset !== undefined) {
            this.sizeOffset = data.sizeOffset;
        }
        if (data.colorOffset) {
            this.colorOffset = (_a = this.colorOffset) !== null && _a !== void 0 ? _a : {};
            if (data.colorOffset.h !== undefined) {
                this.colorOffset.h = data.colorOffset.h;
            }
            if (data.colorOffset.s !== undefined) {
                this.colorOffset.s = data.colorOffset.s;
            }
            if (data.colorOffset.l !== undefined) {
                this.colorOffset.l = data.colorOffset.l;
            }
        }
    }
}
